.template-preview {
  padding: 12px 16px;
  border: 1px solid var(--grey-350);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.template-preview__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.template-preview-info__img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
}

.template-preview-info__icon {
  width: 32px;
  height: auto;
  flex-shrink: 0;
}

.template-preview-info__name {
  margin: 0;
  color: var(--grey-800);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.template-preview-info__subtitle {
  margin: 0;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.template-preview__actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
