.credentials__header {
  display: flex;
  justify-content: space-between;
}

.credentials-header__button-container {
  display: flex;
  flex-direction: row;
  gap: 8px;

  & .credentials-header__button-icon > path {
    fill: #333;
  }
}

.credentials-card {
  padding: 16px 0 0 24px;
}

.credentials__action-button_revoke > span {
  color: var(--error-500);
}
