.credentials {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.credentials__bread-crumbs {
  color: var(--grey-400);
}

.create-credential {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-credential__card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > .select {
    margin-bottom: 0;
  }
}

.credentials-card__list {
  margin-bottom: 16px;
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
}

.create-credential__buttons {
  display: flex;
  gap: 8px;
  align-self: flex-end;
}

.credential-details__spinner {
  width: 170px;
  margin: 0;
}

.choose-signatory__no-data-message {
  margin: 0;
  text-align: center;
  color: #666;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .credentials-card__list {
    grid-template-columns: 1fr;
  }
}
