.settings {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.settings-card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.settings__general {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.settings__name-and-picture {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.settings__category-header {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

.settings__category-subheader {
  margin: 0;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.settings__form_name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  font-size: 16px;
}

.settings__form_name > button {
  align-self: flex-end;
}

.settings__form_password {
  max-width: 45em;
}

.settings__password-form-inputs {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.settings__spinner-container {
  width: 234px;
  display: flex;
  justify-content: center;
}
