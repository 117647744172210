.auth-layout {
  height: 100%;
  position: relative;
  background-image: linear-gradient(rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), url("./images/background.jpeg");
  background-repeat: no-repeat;
  background-position: left top 5%;
  background-size: cover;
}


.auth-layout__logo {
  margin: auto;
  margin-bottom: 16px;
  width: 428px;
}

.auth-layout__logo > p {
  margin: 8px 28px 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.auth-layout__content {
  margin: auto;
  width: 33%;
  max-width: 500px;
  min-width: 400px;
  border-radius: 24px;
  background-color: rgb(255 255 255 / 94%);
  padding: 8vh 3vw;
}

.auth-layout__content > a {
  width: 100%;
}
