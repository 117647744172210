.sst-requests {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sst-requests__table-container {
  padding: 16px 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sst-requests__responsible-person {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
