.documents-upload-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  header {
    padding: 8px 10%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  main {
    background: var(--grey-100);
    flex: 1;
    padding: 30px 10%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.documents-upload-layout__logo {
  width: 70px;
  height: 70px;
}

.documents-upload-layout__navozyme-logo {
  width: 145px;
}

.documents-upload-layout__header-block_first,
.documents-upload-layout__header-block_second {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documents-upload-layout__header-block_first {
  gap: 40px;
  text-transform: uppercase;
  color: #54575C;
  font-size: 20px;
  font-weight: 300;

  & > div {
    width: 70px;
    height: 70px;
  }
}

.documents-upload-layout__header-block_second {
  margin-left: auto;
  white-space: nowrap;
  color: #54575C;
  font-size: 14px;
  font-weight: 300;
}

@media screen and (max-width: 500px) {
  .documents-upload-layout {
    width: 100%;

    header {
      height: 6%;
      padding: 5px;
    }

    main {
      padding: 20px 2%;
      flex-direction: unset;
    }
  }

  .documents-upload-layout__logo {
    width: 40px;
    height: 40px;
  }

  .documents-upload-layout__navozyme-logo {
    width: 60px;
    height: 50px;
  }

  .documents-upload-layout__header-block_first {
    gap: 10px;
    font-size: 14px;

    & > div {
      width: 40px;
      height: 40px;
    }
  }

  .documents-upload-layout__header-block_second {
    font-size: 10px;
  }
}
