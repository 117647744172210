.dashboard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 24px;
}

.dashboard-content {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
}

.dashboard-card {
  padding: 24px 16px;
  position: relative;
}

.wallet-certificates-card {
  display: grid;
  grid-template-columns: 40% auto;
  gap: 24px;
  grid-template-areas:
  "chart count"
  "chart label";
}

.wallet-certificates-chart__container {
  grid-area: chart;
}

.wallet-downloads-chart > .count-box,
.approved-testimonials-chart > .count-box {
  position: absolute;
  top: 24px;
  right: 16px;
}

.bar-chart {
  display: flex;
  flex-direction: column;

  h2,
  p {
    padding: 0;
    margin: 0.5rem 0;
  }    
}

.chart {
  display: flex;
  flex-direction: column;
}

.chart__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.count-box {
  grid-area: count;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--ncap-300);
  padding: 1rem;
  border-radius: 0.5rem;
  color: #000;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  line-break: wrap;
}

.percentage-box {
  grid-area: label;
  padding: 16px;
  border-radius: 8px;
  background:var(--ncap-50);
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: center;
}
