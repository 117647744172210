.responsible-persons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.responsible-persons__header {
  display: flex;
  justify-content: space-between;
}

.responsible-persons__table-container {
  padding: 16px 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.responsible-person__action-button_delete > span {
  color: var(--error-500);
}
