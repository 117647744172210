.sea-service-days__container {
  position: relative;
}

.sea-service-days__list {
  list-style-type: none;
  padding: 0;
  margin: 16px 0 0;

  & > li {
    display: flex;
    flex-direction: row;
    gap: 38px;
    align-items: center;
    margin-bottom: 18px;
  }

  .input-container {
    width: 5em;
  }
}

.sea-service-days__item-header {
  width: 10em;
  color: var(--ncap-800);
  align-self: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sea-service-days__note {
  position: absolute;
  bottom: 0;
  right: 20px;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
