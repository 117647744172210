.auth-header {
  margin: 0 0 0.25em;
  color: var(--grey-800);
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
}

.auth-subheader {
  margin: 0 0 33px;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
}

.sign-in-form .input__label {
  margin: 0 0 4px;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.sign-in-form input {
  padding: 0.5em 1em;
  border: 1px solid var(--grey-300);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.sign-in-form input::placeholder {
  color: var(--grey-400);
}

.sign-in-form__input-description {
  margin: 0.5rem 0 24px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.sign-in-form__forgot-password {
  margin: 16px 0 0;
  align-self: flex-end;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
  color: var(--ncap-500);
}

.sign-in-form > button {
  margin: 33px 0 0;
  width: 100%;
  background-color: var(--ncap-300);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.sign-in__spinner-container {
  margin-top: 33px;
}
