.templates-list {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 1100px) {
  .templates-list {
    grid-template-columns: 1fr;
  }
}
