.issuer-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issuer-form__bread-crumbs {
  color: var(--grey-400);
}

.issuer-form__card {
  padding: 24px 16px;
}

.issuer-form__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issuer-form__inputs-group {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
}

.issuer-form__input {
  margin-bottom: 16px;
}

.issuer-form__header-container {
  margin-bottom: 24px;
}

.issuer-form__header-container > h2 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.issuer-form__header-container > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #666;
}

.issuer-form__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
