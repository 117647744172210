.table {
  border-collapse: collapse;
  width: 100%;
  overflow: scroll;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

.table th {
  border-bottom: 2px solid #ddd;
  padding: 12px 1rem;
  text-align: left;
  background-color: white;
  color: #000;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.table td {
  border-bottom: 1px solid #ddd;
  padding: 1rem;
  color: var(--grey-800);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.table tr:hover {
  background-color: #f5f7f8;
  cursor: default;
}

.paymentTransactionID {
  text-align: center;

  span {
    background: #09b9df;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: white;
  }
}

.more-options-btn {
  cursor: pointer;
}
