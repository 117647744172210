.notification-preview {
  padding: 8px 16px;
  border: 2px solid var(--ncap-100);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-preview__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.notification-preview-info__name {
  margin: 0;
  color: var(--grey-800);
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

.notification-preview-info__description {
  margin: 0;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.notification-preview__button > svg > path {
  fill: var(--ncap-300);
}
