.settings-issuers__header {
  margin-bottom: 24px;
}

.settings-issuers__list {
  margin-bottom: 16px;
}

.settings__add-button > span > svg > path {
  fill: rgb(0 0 0 / 54%);
}
