.add-recipient-form > .MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.credential-details-form__subheader {
  margin-bottom: 18px;
  font-size: 16px;
}

.credential-details-form__template-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  & .picture-picker__header {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }

  & .picture-picker__img {
    width: 60px;
    height: 60px;
  }
}
