.settings__template-designer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settings-template-designer__header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.settings-template-designer__buttons-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.settings-template-designer__buttons-block_first {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.settings-template-designer__buttons-block_second {
  display: flex;
  flex-flow: row wrap;
  gap: 14px;
  justify-content: flex-end;
  align-items: flex-end;
}

.settings-template-designer__fields-block {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 40%;
  min-width: 310px;
}

.template-designer__label-button {
  display: flex;
  align-items: center;
  border: 1px solid rgb(8 184 223 / 50%);
  color: var(--ncap-300);
  padding: 8px 16px;
  text-transform: none;
  box-shadow: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: rgb(8 184 223 / 4%);
    border: 1px solid var(--ncap-300);
  }

  & > input {
    display: none;
  }
}
