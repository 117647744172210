.sidebar {
  background: #FFF;
  z-index: 900;
  box-shadow: 4px 4px 6px rgb(96 96 96 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 2rem;

  .ncap-logo {
    padding-left: 0.5rem;
  }

  .sidebar__navigation {
    margin: 1rem 0 0;
    padding: 0;
    cursor: pointer;
    list-style: none;

    li {
      margin: 0;
      display: flex;
    }
  }

  .sidebar__item {
    a {
      text-decoration: none;
      color: #000A19;
      padding: 1rem 2rem 1rem 12px;
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      
      &.sidebar-item__link_active {
        background-color: var(--ncap-50);
        color: var(--ncap-500);
        font-weight: 800;

        path {
          fill: var(--ncap-500);
        }
      }

      path {
        fill: #000A19;
      }
    }

    .sidebar__link-icon {
      margin-right: 1rem;
      width: 1.2em;
      height: 1.2em;
    }
  }

  .sidebar__item_guest-coc-requests a {
    &.sidebar-item__link_active {
      path {
        fill: none;
        stroke: var(--ncap-500);
      }
    }

    path {
      fill: none;
      stroke: #000A19;
    }
  }

  &__item:hover > a {
    background-color: var(--ncap-50);
  }

  .sidebar__notifications-icons {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .sidebar__link-icon_notification {
    opacity: 0;
    transition: 0.25s;
    animation: notification 0.5s 1;
    animation-fill-mode: forwards;
  }
  
  .sidebar__user-options {
    margin: 0;
    padding: 0 8px;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    li {
      margin: 0;
    }
  }
  
  .sidebar-user-options__item > a {
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    color: #000A19;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    
    &.sidebar-item__link_active {
      background-color: var(--ncap-50);
    }
  }
  
  .sidebar-user-options__item:hover > a {
    background-color: var(--ncap-50);
  }
  
  .sidebar-user-options__item:hover > button {
    background-color: var(--ncap-50);
  }

  .sidebar__user-info {
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  
  .sidebar-user-info__picture {
    border-radius: 20px;
    object-fit: cover;
  }

  .sidebar-user-info__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 12rem;
  }

  .sidebar-user-info__container > p {
    margin: 0;
  }

  .sidebar-user-info__profile {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  }

  .sidebar-user-info__name {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--grey-800);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .sidebar-user-options__item_user-info {
    cursor: default;
  }
  
  .sidebar-user-options__logout {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background: none;
    border: none;
    color: var(--error-500);
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    cursor: pointer;
    width: 100%;

    p {
      margin: 0;
    }

    path {
      fill: var(--error-500);
    }
  }
}

@keyframes notification {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
