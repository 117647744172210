:root {
  --ncap-50: #ECFAFD;
  --ncap-100: #B3E9F5;
  --ncap-300: #08B8DF;
  --ncap-500: #05738C;
  --ncap-600: #005977;
  --ncap-800: #023445;

  --error-500: #EB0000;
  --error-600: #B61A1A;

  --grey-100: #F7F7F7;
  --grey-200: #F3F3F3;
  --grey-300: #E5E5E5;
  --grey-350: #D5D5D5;
  --grey-400: #B3B3B3;
  --grey-500: #666666;
  --grey-600: #333333;
  --grey-800: #242424; 
  --grey-900: #121212; 
}
