.create-template {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.create-template__bread-crumbs {
  color: var(--grey-400);
}

.create-template-form {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.create-template-form__fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-template-form__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
