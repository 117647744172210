.campaigns {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.campaigns__header {
  display: flex;
  justify-content: space-between;
}

.campaigns__table-container {
  padding: 16px 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.campaigns__action-button_delete > span {
  color: var(--error-600);
}
