.leave-dates-label {
  font-size: 18px;
  margin-bottom: 12px;
}

.leave-dates__label {
  display: block;
  margin-bottom: 12px;
  color: var(--grey-800);
  font-size: 16px;
  font-weight: 700;
}

.leave-dates__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    max-width: 400px;
  }

  & > button {
    height: 48px;
  }
}
