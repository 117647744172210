.create-campaign {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-campaign__card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > .select {
    margin-bottom: 0;
  }
}

.create-campaign__card-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.create-campaign__buttons {
  display: flex;
  gap: 8px;
  align-self: flex-end;
}

.credential-details__spinner {
  width: 170px;
  margin: 0;
}
