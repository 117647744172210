.campaign-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-details__card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.campaign-details__container {
  display: grid;
  grid-template-columns: 4fr 5fr;
  column-gap: 24px;
}

.campaign-details__info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-details__info-field {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.campaign-details__info-header {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.campaign-details__users-header {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.campaign-details__buttons {
  display: flex;
  gap: 8px;
  align-self: flex-end;
}
