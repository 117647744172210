.communication-trail-modal__comments-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.communication-trail-modal-comments__item {
  padding: 4px 16px 4px 8px;
  border-bottom: 1px solid var(--grey-200);
}

.communication-trail-modal-comments__item-body, 
.communication-trail-modal-comments__item-created-at {
  margin: 0;
  font-weight: 400;
  line-height: 16px;
}

.communication-trail-modal-comments__item-body {
  font-size: 14px;
}

.communication-trail-modal-comments__item-created-at {
  color: var(--grey-400);
  font-size: 12px;
  font-style: italic;
}
