.coc-requests {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.coc-requests__table-container {
  padding: 16px 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
