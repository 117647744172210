.bar-chart {
  display: flex;
  flex-direction: column;

  h2,
  p {
    padding: 0;
    margin: 0.5rem 0;
  }    
}

.chart {
  &__name-and-filters {
    display: flex;
    flex-direction: column;
  }
  
  &__filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 0;
    cursor: pointer;
    font-weight: 500;
  }

  &__filter {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;

    &.active {
      background-color: #09b9df;
      color: white;
    }
  }

  &__name {
    font-weight: bold;
  }

  &__body {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.certificates-count-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--ncap-300);
  padding: 1rem;
  border-radius: 0.5rem;
  color: #000;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  line-break: wrap;
}
