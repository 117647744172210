.mobile-users {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mobile-users__header {
  display: flex;
  justify-content: space-between;
}

.mobile-users__table-container {
  padding: 16px 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mobile-users__action-button_delete > span {
  color: var(--error-500);
}
