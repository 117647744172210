.coc-request-detailed {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.coc-request-detailed__header {
  display: flex;
  justify-content: space-between;
}

.coc-request-detailed__header_left {
  display: flex;
  align-items: center;
  gap: 19px;

  & svg {
    width: 13px;
    height: 22px;
    transform: rotate(180deg);

    & > path {
      fill: var(--grey-800);
    }
  }
}

.coc-request-detailed__header_right {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: fit-content;
  flex-wrap: wrap;
}

.coc-request-detailed__card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  
  .responsible-person-form__button {
    align-self: flex-end;
    padding: 8px 44px;
  }

  .input-container {
    margin-bottom: 16px;
  }

  & > div {
    margin-bottom: 24px;
  }
}

.coc-request-detailed__icon_approve > path {
  fill: #FFF;
}

.coc-request-detailed__icon_return > path {
  fill: #333;
}

.coc-request-detailed__icon_error > path {
  fill: var(--error-500);
}

.coc-request-detailed__icon_actions {
  transform: rotate(90deg);
}

.coc-request-detailed__icon_actions > path {
  fill: #333;
}

.coc-request-detailed__other-documents {
  display: flex;
  flex-direction: column;
}

.coc-request-detailed__download-button {
  margin-top: 16px;
  width: 12em;
  align-self: flex-end;
}

.coc-request-detailed__download-icon > path {
  fill: var(--ncap-300);
}

.personal-information-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card__header {
  margin-bottom: 24px;
}

.coc-request-form__group-header {
  padding: 8px 16px 8px 8px;
  border-bottom: 2px solid var(--grey-200);
}

.coc-request__dates {
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 400px;
}

.coc-request__input-info {
  margin: 0;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.coc-request__input {
  margin-bottom: 16px;
}

.coc-request-detailed__section {
  max-width: 65em;
}
