.picture-picker__header {
  margin-bottom: 16px;
  color: var(--grey-800);
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

.picture-picker__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.picture-picker__img {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
}
