.coc-request-document-preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-200);
  padding: 4px 26px 4px 8px;
}

.coc-request-document-preview__name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.coc-request-document-preview__link {
  justify-self: flex-end;
}
