.import-spreadsheet__content, .file-uploader__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.file-uploader__content:hover {
  cursor: pointer;
}

.file-uploader__content_highlighted {
  color: var(--ncap-300);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.file-uploader__template-link {
  margin-bottom: 0;
  font-size: 14px;

  & > button {
    color: var(--ncap-300);
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.import-spreadsheet__success {
  width: 60px;
  height: 60px;

  path {
    fill: var(--ncap-300); 
  }
}

.import-spreadsheet__error {
  width: 60px;
  height: 60px;

  path {
    fill: var(--error-500); 
  }
}

.import-spreadsheet__error-message {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.import-spreadsheet__error-text {
  margin: 0;
}
