.forgot-password {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.forgot-password .input__label {
  margin: 0 0 4px;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.forgot-password input {
  padding: 0.5em 1em;
  border: 1px solid var(--grey-300);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.forgot-password input::placeholder {
  color: var(--grey-400);
}

.forgot-password__button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.forgot-password__button-container > button,
.forgot-password__button-container > a {
  width: 100%;
}

.forgot-password__spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
