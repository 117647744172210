.card {
  padding: 10px 0;
  background: #fff;
  box-shadow: 1px 2px 4px rgb(196 196 196 / 36%);
  border-radius: 12px;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
}

.card__header {
  color: #040714;
}

.card__header_warning {
  color: var(--error-500);
}
