.sst-request-detailed {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sst-request-detailed__header {
  display: flex;
  justify-content: space-between;
}

.sst-request-detailed__header_left {
  display: flex;
  align-items: center;
  gap: 19px;

  & svg {
    width: 13px;
    height: 22px;
    transform: rotate(180deg);

    & > path {
      fill: var(--grey-800);
    }
  }
}

.sst-request-detailed__header_right {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: fit-content;
  flex-wrap: wrap;
}

.sst-request-detailed__card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  
  .responsible-person-form__button {
    align-self: flex-end;
    padding: 8px 44px;
  }

  .input-container {
    margin-bottom: 16px;
  }
}

.sst-request-detailed__attachment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  background-color: var(--ncap-50);
  border-radius: 8px;
  border: 1px solid var(--grey-350);
  padding: 12px 16px;
}

.sst-request-detailed__attachment-title {
  display: flex;
  flex-direction: row;
  gap: 4px;

  & > div {
    align-self: center;
  }
}

.sst-request-detailed__attachment-icon {
  width: 32px;
  height: 32px;
  align-self: center;

  & > path {
    fill: var(--ncap-300);
  }
}

.sst-request-detailed__attachment-header {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.sst-request-detailed__attachment-text {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.sst-request-detailed__attachment-button {
  align-self: center;
}

.sst-request-detailed__attachment-spinner-container {
  align-self: center;
  width: 190px;
}

.sst-request-detailed__card_first > div {
  margin-bottom: 24px;
}

.sst-request-detailed__icon_approve > path {
  fill: #FFF;
}

.sst-request-detailed__icon_return > path {
  fill: #333;
}

.sst-request-detailed__icon_error > path {
  fill: var(--error-500);
}

.sst-request-detailed__icon_actions {
  transform: rotate(90deg);
}

.sst-request-detailed__icon_actions > path {
  fill: #333;
}

.personal-information-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card__header {
  margin-bottom: 24px;
}

.group__header {
  margin-bottom: 16px;
}

.sst-request__dates {
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 400px;
}

.sst-request__input-info {
  margin: 0;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.sst-request__input {
  margin-bottom: 16px;
}
