.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  & > div:first-child {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    & > div {
      border-radius: 5px;
      border: 1px solid var(--grey-300);
      box-shadow: 0 2px 2px rgb(0 0 0 / 5%);
      background-color: #fff;
    }

    &:focus {
      border: 1px solid #005a77;
      outline: none;
    }
  }

  .form-input {
    margin-top: 8px;
  }
}
