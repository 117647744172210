.notifications {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.notifications-card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}
