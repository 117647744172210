.upload-guest-documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.upload-guest-documents__card {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;

  & > .spinner-container {
    align-self: flex-end;
    margin: 0;
    width: 180px;
  }
}

.upload-guest-documents__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  & .template-preview-info__name {
    max-width: 180px;
  }
}

.upload-guest-documents__error-message {
  color: var(--error-500);
}

.upload-guest-documents__supplied-by {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-guest-documents__submit-button {
  align-self: flex-end;
  text-align: center;
}

.upload-guest-documents-error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-guest-documents-error__logo {
  width: 428px;
  margin-bottom: 70px;
}

.upload-guest-documents-error__header {
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}

.upload-guest-documents-error__text {
  font-size: 24px;
  text-align: center;
  margin: 40px 0 22px;
}

.upload-guest-documents-error__text_contact {
  margin: 0 0 60px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  & > a {
    display: block;
    text-decoration: underline;
    color: #2B2B2B;
  }
}

.upload-guest-documents-error__back-button {
  width: 30%;
}

@media screen and (max-width: 1200px) {
  .upload-guest-documents__list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 805px) {
  .upload-guest-documents__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .upload-guest-documents-error {
    padding: 0 15px;
  }

  .upload-guest-documents__card {
    padding: 20px 15px;

    & > p {
      font-size: 12px;
    }
  }
  
  .upload-guest-documents__submit-button {
    align-self: center;
    width: 100%;

    & > button {
      width: 100%;
    }
  }

  .upload-guest-documents-error__logo {
    width: 320px;
    margin: 40px auto;
  }

  .upload-guest-documents-error__header {
    font-size: 24px;
    line-height: 30px;
  }

  .upload-guest-documents-error__text {
    margin: 10px 0 28px;
    font-size: 16px;
    line-height: 20px;
  }

  .upload-guest-documents-error__text_contact {
    font-size: 12px;
    line-height: 20px;
    margin: 80px 0 auto;
  }
}
