.choose-template__no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #666;
    font-size: 16px;
    font-weight: 500;
  }
}

.choose-template__select {
  position: relative;
}

.choose-template__select-label {
  position: absolute;
  top: 0;
  left: 180px;

  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--grey-500);
}
