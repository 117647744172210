.coc-payments {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.coc-payments__table-container {
  padding: 16px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.coc-payments__card-header,
.coc-payments__table-actions,
.coc-payments__date-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.coc-payments__table-actions {
  gap: 24px;
}

.coc-payments__date-range {
  gap: 8px;
}
