.company-user-form__inputs-container {
  flex-direction: row-reverse;
}

.company-user-form__issuer-basic-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 16px;
}

.company-user-form__issuers-container,
.company-user-form__templates-container {
  width: 33%;
  align-self: flex-end;
}
