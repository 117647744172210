.modal__header {
  display: flex;
  align-items: center;
  gap: 24px;
}

.modal__header_icon {
  margin-left: 8px;
}

.modal__loading {
  width: 50%;
  display: flex;
  justify-content: center;
}
