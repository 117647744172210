#root {
  height: calc(var(--vh, 1vh) * 100);
}

.base-layout {
  height: 100%;
  display: flex;

  aside {
    min-width: 16rem;
    max-width: 18rem;
  }

  main {
    background: var(--grey-100);
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
