.credentials-creation__success {
  width: 60px;
  height: 60px;

  path {
    fill: var(--ncap-300); 
  }
}

.credentials-creation__failed {
  width: 60px;
  height: 60px;

  path {
    fill: var(--error-500); 
  }
}
