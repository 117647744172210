.file-preview {
  padding: 12px 16px;
  border: 1px solid var(--grey-350);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.file-preview__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
