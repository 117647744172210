.file-uploader {
  display: block;
  width: 100%;
  min-width: unset !important;
  max-width: unset !important;
  box-sizing: border-box;

  // border: 1px dashed var(--grey-400) !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23B3B3B3FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 24px;

  & > div {
    justify-content: center;
    align-items: center;
  }

  & > svg {
    display: none;
  }

  & .file-types {
    display: none;
  }
}

.file-uploader:focus-within {
  outline: none !important;
}

.file-uploader__content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  & > p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.file-uploader__error {
  margin: 10px 0 0;
  color: var(--error-500);
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .file-uploader__content  > p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
