@font-face {
  font-family: Inter;
  src: url("./styles/fonts/Inter.ttf") format(opentype);
  font-weight: 100 200 300 400 700;
}

@font-face {
  font-family: Inter;
  src: url("./styles/fonts/Inter-Medium.ttf") format(opentype);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("./styles/fonts/Inter-SemiBold.ttf") format(opentype);
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("./styles/fonts/Inter-ExtraBold.ttf") format(opentype);
  font-weight: 800;
}

body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
button,
table th,
table td,
select {
  font-family: Inter, sans-serif;
}

h1 {
  margin: 0;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
}

h2 {
  margin: 0;
  color: var(--grey-800);
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

textarea {
  resize: none;
}

input::placeholder, textarea::placeholder {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--grey-400);
}

@media screen and (max-width: 500px) {
  h2 {
    font-size: 16px;
  }
}
