.create-template__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-template-header__buttons-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.create-template-header__bread-crumbs {
  color: var(--grey-400);
}

.create-template__content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 86vh;
}

.create-template__card {
  padding: 24px 16px;
  height: 100%;
}

.create-template__form-title {
  margin-bottom: 24px;
}

.template-designer__header {
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-designer__buttons-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-designer__buttons-block_first {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.template-designer__buttons-block_second {
  display: flex;
  flex-flow: row wrap;
  gap: 14px;
}

.template-designer__fields-block {
  display: flex;
  flex-direction: column;
  gap: 14px;
  min-width: 310px;
}

.template-designer__button-icon_discard > path {
  fill: var(--error-500);
}

.template-designer__label-button {
  display: block;
  text-align: center;
  border: 1px solid rgb(8 184 223 / 50%);
  color: var(--ncap-300);
  padding: 8px 16px;
  text-transform: none;
  box-shadow: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: rgb(8 184 223 / 4%);
    border: 1px solid var(--ncap-300);
  }

  & > input {
    display: none;
  }
}

.create-template-designer {
  width: 250%;
  min-width: 900px;
}
