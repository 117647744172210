.responsible-person-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.responsible-person-form__name {
  margin-bottom: 24px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.responsible-person-form__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
