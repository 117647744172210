.input {
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    margin-bottom: 0.5rem;
    color: var(--grey-800);
    font-size: 14px;
    font-weight: 700;
    gap: 4px;
  }

  .error {
    min-height: 0.5rem;
  }
}

.input__label_required {
  color: var(--error-500);
}

.form-control {
  position: relative;
}

.form-input {
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid var(--grey-300);
  box-shadow: 0 2px 2px rgb(0 0 0 / 5%);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &:focus {
    border: 1px solid #005a77;
    outline: none;
  }

  &:disabled {
    background-color: var(--grey-100);
  }
}

.form-input__icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 56%;
}

.form-input_checkbox {
  box-shadow: none;
}

.input__info {
  margin: 4px 0 0;
  color: var(--grey-400);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
