.add-recipient-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add-recipient-form__inputs-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.input-container_short {
  max-width: 21em;
}

.add-recipient-form__actions {
  display: flex;
  gap: 16px;
}

.add-recipient-form__checkboxes-container {
  display: flex;
  flex-direction: column;
}

.add-recipient-form__inputs-container > .input-container,
.add-recipient-form__inputs-container > .select,
.add-recipient-form__inputs-container > .date-picker {
  width: 100%;
}

.add-recipient-form__inputs-container > .select {
  max-width: 33%;
}

.add-recipient-form__header-container > h2 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.add-recipient-form__header-container > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #666;
}

.add-recipient-form__warning {
  margin: 0;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--error-500);
}

.settings__form-button_secondary svg {
  transform: rotate(180deg);

  & > path {
    fill: #333;
  }
}

.credential-details-form__dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.credential-details-form__dates > .date-picker {
  width: 100%;
}

// @media screen and (max-width: 1000px) {
//   .add-recipient-form__inputs-container > .input-container {
//     max-width: 32%;
//   }
// }
