.uploader-card {
  padding: 20px 0;
  border: 1px #CBD0DC solid;
  box-shadow: none;
}

.uploader-card_error {
  border: var(--error-500) solid 1px;
}

.uploader-card__header {
  padding: 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #CBD0DC;
}

.uploader-card-header__logo {
  width: 40px;
}

.uploader-card-header__title {
  margin: 0;
  color: #292D32;
  font-size: 14px;
  font-weight: 500;
}

.uploader-card-header__subtitle {
  margin: 0;
  color: #A9ACB4;
  font-size: 12px;
  font-weight: 500;
}

.uploader-card__main {
  padding: 20px 30px;

  & > .file-preview {
    margin-top: 10px;
  }
}

.uploader-card__text_uploaded {
  margin: 20px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

@media screen and (max-width: 500px) {
  .uploader-card__text_uploaded {
    margin: 10px 0 0;
    font-size: 12px;
  }
}
